import gten from '../images/slicing/home/GTEN.png'
import imagine from '../images/slicing/home/IMAGINE.png'
import develop from '../images/slicing/home/DEVELOP.png'
import modernize from '../images/slicing/home/MODERNIZE.png'
import manage from '../images/slicing/home/MANAGE.png'
export default [{
    subHeading: 'We Are',
    heading: 'GTEN',
    knowMore:'/aboutus/',
    description: `Welcome to GTEN Technologies. An innovative lab of some of the best minds in the digital domain today. We are disruptors with a simple goal of helping your business achieve milestones through the use of the latest technology.

    \nWe provide customized solutions to help companies leverage their capabilities and create scalable products efficiently and rapidly. With faster response time and our unmatched expertise, our solutions help you to address your customers’ expectations efficiently, while receiving faster outputs and better revenue-generation for enhanced business performance. `,
    imageSrc: gten,
    altText: 'Image Not Found',
    specifications: ['Analytics & Data', 'Certified Company']
},
{
    subHeading: 'We Partner To',
    heading: 'IMAGINE',
    knowMore:'/startupstudio/',
    description: `How to engage with your customers, employees, community and industry. We work with you to design and deliver new digital experiences, revenue streams or business models to meet customer expectations, increase customer engagement and make you the leader.`,
    imageSrc: imagine,
    altText: 'Image Not Found',
    specifications: ['Analytics & Data', 'Certified Company']
},
{
    subHeading: 'We Partner To',
    heading: 'DEVELOP',
    knowMore:'/productdevelopment/',
    description: `Products and platforms by streamlining your software engineering process to drive efficiency and enable resiliency, helping achieve your goals and objectives.`,
    imageSrc: develop,
    altText: 'Image Not Found',
    specifications: ['Analytics & Data', 'Certified Company']
},
{
    subHeading: 'We Partner To',
    heading: 'MODERNIZE',
    knowMore:'/legacymodernization/',
    description: `We make you ready for the future by modernizing your existing applications taking full advantage of automation, AI and cloud technology of future. Bringing greater agility, scalability to your applications, infrastructure and processes. We make you ready for the new world.`,
    imageSrc: modernize,
    altText: 'Image Not Found',
    specifications: ['Analytics & Data', 'Certified Company']
},
{
    subHeading: 'We Partner To',
    heading: 'MANAGE',
    knowMore:'/productengineering/',
    description: `We keep your applications no matter they are on cloud or in-house data centers available, reliable when you need them. We maintain and optimize the heartbeat of your operations, our 24X7 teams work with you to ensure smooth operations to facilitate business and growth.`,
    imageSrc: manage,
    altText: 'Image Not Found',
    specifications: ['Analytics & Data', 'Certified Company']
}]