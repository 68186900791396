import { navigate } from 'gatsby'
import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { servicesCardData } from '../../staticdata/homepageServicesData'
const ServicesSection = ({ selectService, selectedService }) => {
    return (
        <Container fluid style={{ background: '#f7f6f9' }}>
            <Container >
                <Row>
                    <Col className="services-section-right d-md-none">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="line"></div>
                            <div>
                                <h6 className="subheading">Our Services</h6>
                            </div>
                        </div>
                        <h2 className="sub-subheading">{selectedService ? selectedService.heading : servicesCardData[0][0].heading}</h2>
                    </Col>
                </Row>
                <Row className="d-md-none services-section-right px-3">
                    <p>{selectedService ? selectedService.hoverText : servicesCardData[0][0].hoverText}
                        {selectedService && selectedService.sublist && <ul>
                            {selectedService && selectedService.sublist && selectedService.sublist.map((listItem, index) => (
                                <li key={`list_${index}`}>{listItem}</li>
                            ))}
                        </ul>}
                    </p>
                    <Button onClick={() => navigate(selectedService ? selectedService.key : servicesCardData[0][0].key)}>
                                    Read More <div className="line-white"></div>
                                </Button>
                </Row>
                <Container className="d-md-none">
                    {
                        servicesCardData.map((serviceRow, rowIndex) => {
                            return (
                                <Row key={rowIndex + 'service'} className={rowIndex === 0 && 'mt-4'} >
                                    {serviceRow.map((service, colIndex) => (
                                        <Col sm="6" md="3" xs="6"
                                            key={colIndex + 'servicecard'}
                                            className="mb-2"
                                            style={{ padding: '2px' }}
                                            onClick={() => selectService(service)}>
                                            <Card className={`services-card-homepage ${selectedService && selectedService.heading === service.heading && 'last-card'}`} style={{ height: '100%' }}>
                                                <Card.Body>
                                                    <Row>

                                                        <Card.Img style={{ width: '66px', height: '66px', marginLeft: '10px' }} variant="top" src={service.imageSrc} />

                                                    </Row>
                                                    <Card.Title className="card-heading">{service.heading}</Card.Title>
                                                    <Card.Text className="card-description">{service.description}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            )
                        })
                    }
                </Container>
                <Row className="py-5 d-none d-md-flex">
                    <Col>
                        {
                            servicesCardData.map((serviceRow, rowIndex) => {
                                return (
                                    <Row key={rowIndex + 'service'} className={rowIndex === servicesCardData.length - 1 && 'mt-2'}>
                                        {serviceRow.map((service, colIndex) => (
                                            <Col sm="6" key={colIndex + 'servicecard'} onClick={() => selectService(service)} className="mt-2" style={{ cursor: 'pointer' }}>
                                                <Card className={`services-card-homepage ${selectedService && selectedService.heading === service.heading && 'last-card'}`} style={{ height: '100%' }}>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col md="4" sm="6" className="mt-2">
                                                                <Card.Img height="44px" width="44px" variant="top" src={service.imageSrc} />
                                                            </Col>
                                                        </Row>
                                                        <Card.Title>{service.heading}</Card.Title>
                                                        <Card.Text className="text-justify">{service.description}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                )
                            })
                        }
                    </Col>
                    <Col className="services-section-right">
                        <div className="d-none d-md-flex justify-content-start align-items-center">
                            <div className="line"></div>
                            <div>
                                <h6 className="subheading">Our Services</h6>
                            </div>
                        </div>
                        <h2 className="d-none d-md-block">{selectedService ? selectedService.heading : servicesCardData[0][0].heading}</h2>
                        <p className="d-none d-md-block ">{selectedService ? selectedService.hoverText : servicesCardData[0][0].hoverText}
                            <br /><br />
                            {selectedService && selectedService.sublist && <ul style={{ listStyleType: 'arrow' }}>
                                {selectedService && selectedService.sublist && selectedService.sublist.map((listItem, index) => (
                                    <li key={`list_${index}`}>{listItem}</li>
                                ))}
                            </ul>}
                        </p>
                        <Row>
                            <Col>
                                <Button onClick={() => navigate(selectedService ? selectedService.key : servicesCardData[0][0].key)}>
                                    Read More <div className="line-white"></div>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default ServicesSection;