import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import HomeContainer from "../containers/HomeContainer"
import { graphql } from "gatsby"
const IndexPage = props => (
  <Layout
    pageInfo={{ pageName: "Home" }}
    styleClass="home-page"
    location={props.location.pathname}
  >
    <SEO title="Home" keywords={[`gten`, `GTEN`, `technology`]} />
    <HomeContainer data={props.data && props.data.allContentfulBlogs.nodes} />
  </Layout>
)

export default IndexPage
export const query = graphql`
  {
    allContentfulBlogs {
      nodes {
        contentful_id
        createdAt
        tag
        title
        url
        media {
          file {
            url
          }
        }
        description {
          content {
            content {
              value
            }
          }
        }
      }
      totalCount
    }
  }
`
