import React from 'react'
import { Link } from 'gatsby'
const CustomButton = ({ reference, className,buttonTitle,lineColor,style }) => {
    return (
        <>
            <Link to={reference} className={className}>
                {buttonTitle}
                <div className={lineColor} style={style}>

                </div>
            </Link>
        </>
    );
};

export default CustomButton