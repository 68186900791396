import banner1 from '../images/slicing/home/banner1.1.jpg'
import banner2 from '../images/slicing/home/banner2.1.jpg'
import banner5 from '../images/slicing/home/banner3.jpg'

export default [
{
    heading: 'Product Engineering is our DNA',
    description: `Enhancing Customer Engagement through omni-channel digital applications.`,
    imageSrc: banner1,
    redirection:'/productengineering/',
    altText: 'Image Not Found',
},
{
    heading: 'User & Domain Focused Solutions',
    description: `Delivering market-leading products in Healthcare, Banking, Financial Services & Insurance.`,
    imageSrc: banner2,
    redirection:'/industries/',
    altText: 'Image Not Found',
},
{
    heading: 'Give Ideas Wings To Fly',
    description: `Launch a successful start-up venture with a team which has done it multiple times. From ideation to product development to cash positive revenue we work with you till the end.`,
    imageSrc: banner5,
    redirection:'/startupstudio/',
    altText: 'Image Not Found',
}]