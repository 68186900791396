import React from 'react'
import { Carousel, Container, Row, Col } from "react-bootstrap"
import aboutInfo from '../../staticdata/homepageAboutSection'
import CustomButton from '../elements/CustomButton'
const AboutSectionHomePage = () => {
    return (
        <Container fluid>
            <Container>
                <Carousel
                    controls={false}
                    className="about-section"
                    interval={5000}
                    defaultActiveIndex={0}

                    style={{ background: '#ffffff' }}>
                    {
                        aboutInfo.map((carouselItem, index) => (
                            <Carousel.Item key={index + 'about'}>
                                <Container fluid>
                                    <Row className="py-5">
                                        <Col className="carousel-columns" md="7">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div className="line"></div>
                                                <div>
                                                    <h6 className="carousel-section-subheading">{carouselItem.subHeading}</h6>
                                                </div>
                                            </div>
                                            
                                            <h2 className="heading">{carouselItem.heading}</h2>
                                            <img
                                                className="d-md-none d-block w-100 about-image"
                                                src={carouselItem.imageSrc}
                                                alt={carouselItem.altText}
                                            />
                                            <p style={{height:'150px'}}>{carouselItem.description}</p>
                                            <Container>
                                                {/* <Row>
                                                    {carouselItem.specifications.map((specification, index) => (
                                                        <Col key={index + 'aboutsection'}>
                                                            <h6 className="font-weight-bold specification">{specification}</h6>
                                                        </Col>
                                                    ))}
                                                </Row> */}
                                                <Row className="py-3">
                                                    <Col>
                                                        <CustomButton
                                                            buttonTitle="Know More"
                                                            reference={carouselItem.knowMore}
                                                            style={{marginTop:'7%'}}
                                                            className="btn btn-outline"
                                                            lineColor="line-standard" />
                                                    </Col>
                                                </Row>
                                            </Container>

                                        </Col>
                                        <Col className="d-none d-md-block carousel-columns-images about-image">
                                            <img
                                                className="d-block w-100"
                                                src={carouselItem.imageSrc}
                                                alt={carouselItem.altText}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Carousel.Item>
                        ))
                    }
                </Carousel>
                <hr />
            </Container>
        </Container>

    );
};

export default AboutSectionHomePage;