import React, { Component } from "react"
import TopCarousel from "../components/homepage/TopCarousel"
import AboutSectionHomePage from "../components/homepage/AboutSectionHomePage"
import AchievementsSections from "../components/homepage/AchievementsSections"
import ServicesSection from "../components/homepage/ServicesSection"
import TestimonialComponent from "../components/services/TestimonialComponent"
import CaseStudyComponent from "../components/services/CaseStudyComponent"
class HomeContainer extends Component {
  state = {
    selectedService: null,
  }
  componentDidMount() {
    const s = document.createElement("script")
    s.type = "text/javascript"
    s.async = true
    s.innerHTML =
      "var dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-1LHQC81GE9');"
    document.body.appendChild(s)
  }
  toggleServiceModal = selectedService => {
    this.setState(prev => ({
      selectedService,
    }))
  }
  render() {
    const { selectedService } = this.state
    return (
      <>
        <TopCarousel />
        <AboutSectionHomePage />
        <AchievementsSections />
        <ServicesSection
          selectService={this.toggleServiceModal}
          selectedService={selectedService}
        />
        <CaseStudyComponent />
        <TestimonialComponent />
      </>
    )
  }
}

export default HomeContainer
