import React from 'react'
import { Carousel } from "react-bootstrap"
import CustomButton from '../elements/CustomButton'
import carouseldata from '../../staticdata/homepageCarouselData'
const TopCarousel = () => {
    return (
        <>
            <Carousel interval={4000} defaultActiveIndex={1} controls={false} className="top-carousel">
                {
                    carouseldata.map((slide, index) => (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={slide.imageSrc}
                                alt={slide.altText}
                            />

                            <Carousel.Caption>
                                <h1 className="text-nowrap slide-heading">{slide.heading}</h1>
                                <p className="carousel-para">{slide.description}</p>
                                <div>
                                    <CustomButton
                                        reference={slide.redirection}
                                        buttonTitle="Know More"
                                        className="btn btn-top-carousel"
                                        lineColor="line-white" />
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))
                }
            </Carousel>
        </>
    );
};

export default TopCarousel;