import React from 'react'
import { Container, Row } from 'react-bootstrap'
import AchievementCard from './AchievementCard'
const AcheivementsSections = props => {
    return (
        <>
            <Container fluid>
                <Container>
                    <Row className="py-3 achievement-section">
                        <AchievementCard colClass="col-md-3 col-sm-6 col-xs-6 border-sm-bottom" className="achievement-column" number='50+' heading="Projects" />
                        <AchievementCard colClass="col-md-3 col-sm-6 col-xs-6 border-sm-bottom" className="achievement-column" number='10+' heading="Years of Product Engineering" />
                        <AchievementCard colClass="col-md-3 col-sm-6 col-xs-6" className="achievement-column" number='04+' heading="Offices in the World" />
                        <AchievementCard colClass="col-md-3 col-sm-6 col-xs-6" className="achievement-column" number='30+' heading="Happy Customers" />
                    </Row>
                </Container>

            </Container>
        </>
    );
};

export default AcheivementsSections